@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,400;1,200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Jost:ital,wght@0,400;1,200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200&display=swap');
body{
    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='800' height='800' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='88' y1='88' x2='0' y2='0'%3E%3Cstop offset='0' stop-color='%23002b85'/%3E%3Cstop offset='1' stop-color='%230043a3'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='75' y1='76' x2='168' y2='160'%3E%3Cstop offset='0' stop-color='%23868686'/%3E%3Cstop offset='0.09' stop-color='%23ababab'/%3E%3Cstop offset='0.18' stop-color='%23c4c4c4'/%3E%3Cstop offset='0.31' stop-color='%23d7d7d7'/%3E%3Cstop offset='0.44' stop-color='%23e5e5e5'/%3E%3Cstop offset='0.59' stop-color='%23f1f1f1'/%3E%3Cstop offset='0.75' stop-color='%23f9f9f9'/%3E%3Cstop offset='1' stop-color='%23FFFFFF'/%3E%3C/linearGradient%3E%3Cfilter id='c' x='0' y='0' width='200%25' height='200%25'%3E%3CfeGaussianBlur in='SourceGraphic' stdDeviation='12' /%3E%3C/filter%3E%3C/defs%3E%3Cpolygon fill='url(%23a)' points='0 174 0 0 174 0'/%3E%3Cpath fill='%23000' fill-opacity='0.71' filter='url(%23c)' d='M121.8 174C59.2 153.1 0 174 0 174s63.5-73.8 87-94c24.4-20.9 87-80 87-80S107.9 104.4 121.8 174z'/%3E%3Cpath fill='url(%23b)' d='M142.7 142.7C59.2 142.7 0 174 0 174s42-66.3 74.9-99.3S174 0 174 0S142.7 62.6 142.7 142.7z'/%3E%3C/svg%3E");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: top left;
}

.client1{
    width: 400px;
    height: 200px;
    position: relative;
    background-color:transparent;
    z-index: index 1;
}
.theCard{
    position: absolute;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: all 0.5s ease;
    background-color: transparent;
    border-radius: 20px;
    -webkit-transition: .7s linear;
}
.theCard:hover{
    transform: rotateY(180deg);
    border-radius: 20px;
} 
.thefront{
    margin-top: 20px;
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background: #004BAD;
    color: #333;
    border-radius: 20px;
}
.theBack{
    padding-top: 20px;
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background: transparent;
    color: #333;
    transform: rotateY(180deg);
    border-radius: 20px;
}
/* navigation component */

.Navlinks{
    color: rgba(0,128,55,1);
    text-decoration: none;
    align-items: flex-end;
}
.NavList{
    list-style: none;
    display: flex;
    flex-direction: row;
}
.NavItems{
    margin: 0% 2%;
}
.NavListSing{
    list-style: none;
    display: flex;
    flex-direction: row;
}
.navToolbar{
    display: flex;
    justify-content: space-around;
}
.navBarCustom{
    margin-top: 30px;
    z-index:3;
}
.preNavbar{
    width: 100%;
    height: 30px;
    font-family: 'Raleway', sans-serif;
    background-color: rgba(0,128,55,1);
    position: fixed;
    z-index:3;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.navBarindex{
    z-index:3;
}
.prenavBarinfo{
    list-style: none;
    display: flex;
    flex-direction: row;
    margin-top: -10px;
    padding: 0%;
    font-family: 'Raleway', sans-serif;
}
.prenavBarinfo >li{
    padding: 0%;
    flex-direction: row;
}
.preNavItems{
    margin-right: 10%;
}
.preNavItems > p{
    display: flex;
    flex-direction: row;
}
.preNavItems > p > span{
    display: flex;
    flex-direction: row; 
}

/* product Page Hero slider */
.heroContainer{
    display: flex;
    justify-content: center;
    padding-top: 8%;
}
.image{
    width: 30%;
    height: auto;
    margin-left: 35%;
    border-radius: 10px;
}
.left-arrow{
    position: absolute;
    top: 50%;
    right: 32px;
    font-size: 3rem;
    color: transparent;
    z-index: 10;
    user-select: none;
}
.right-arrow{
    position: absolute;
    top: 50%;
    left: 32px;
    font-size: 3rem;
    color: transparent;
    z-index: 10;
    user-select: none;
}
.showcase2{    
    width: 100%;
    height: 300px;
    background-color: rgba(0,128,55,.5);    
    color: 	#000;
    margin-top: 3%;
    font-family: 'Raleway', sans-serif;
    border-bottom-right-radius: 150px;
    border-top-left-radius: 150px;
}
.text{
    display: grid;
    grid-template-columns: repeat(2 ,1fr);
    justify-content: center;
}
.text>p{
    margin-top: 5%;
    padding: 3%;
    grid-column: 2;
    font-size: 3rem;
    font-family: 'Raleway', sans-serif;
    color: #004BAD;
}
.LigoSuply{
    margin-top: 5%;
    margin-left: 40%;
    grid-column: 1;
}



.social {
    margin-top: 10%;
    margin-bottom: 7%;
    display: grid;
    height: 100%;
    width: 100%;
    font-family: 'Raleway', sans-serif;
    place-items: center;
  }
  
  .wrapper {
    margin-top: 3%;
    display: inline-flex;
  }
  
  .wrapper .icon {
    position: relative;
    background-color: #ffffff;
    border-radius: 50%;
    padding: 15px;
    margin: 10px;
    width: 50px;
    height: 50px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  
  .wrapper .tooltip {
    position: absolute;
    top: 0;
    font-size: 14px;
    background-color: #ffffff;
    color: #ffffff;
    padding: 5px 8px;
    border-radius: 5px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  
  .wrapper .tooltip::before {
    position: absolute;
    content: "";
    height: 8px;
    width: 8px;
    background-color: #ffffff;
    bottom: -3px;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  
  .wrapper .icon:hover .tooltip {
    top: -45px;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
  
  .wrapper .icon:hover span,
  .wrapper .icon:hover .tooltip {
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
  }
  
  .wrapper .facebook:hover,
  .wrapper .facebook:hover .tooltip,
  .wrapper .facebook:hover .tooltip::before {
    background-color: #3b5999;
    color: #ffffff;
  }
  
  .wrapper .twitter:hover,
  .wrapper .twitter:hover .tooltip,
  .wrapper .twitter:hover .tooltip::before {
    background-color: #46c1f6;
    color: #ffffff;
  }
  
  .wrapper .instagram:hover,
  .wrapper .instagram:hover .tooltip,
  .wrapper .instagram:hover .tooltip::before {
    background-color: #e1306c;
    color: #ffffff;
  }
  
  .wrapper .github:hover,
  .wrapper .github:hover .tooltip,
  .wrapper .github:hover .tooltip::before {
    background-color: #333333;
    color: #ffffff;
  }
  
  .wrapper .youtube:hover,
  .wrapper .youtube:hover .tooltip,
  .wrapper .youtube:hover .tooltip::before {
    background-color: #de463b;
    color: #ffffff;
  }

.navImageFootercont{
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    text-align: center;
}
.showcaseContainer{
    display: flex;
    justify-content: center;
    padding-top: 7%;
}
.showcaseTrhee{
    width: 100%;
    height: 300px;
    background-color: rgba(0,128,55,.7);    
    color: 	#000;
    margin-top: 3%;
    font-family: 'Raleway', sans-serif;
    border-bottom-right-radius: 150px;
    border-top-left-radius: 150px;
}
.textTwo{
    display: grid;
    grid-template-columns: repeat(2 ,1fr);
    justify-content: center;
}
.textTwo>p{
    margin-top: 5%;
    padding: 1%;
    grid-column: 2;
    font-size: 2.5rem;
    font-family: 'Raleway', sans-serif;
    color: #FFFF;
}
.LigoSuplytwo{
    margin-top: 5%;
    margin-left: 40%;
    grid-column: 1;
}

.payload{
    width: 100%;
    height: 800px;
    margin-top: 4%;
    margin-left: 2%;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    column-gap: 1%;
}
.cardContainer{
    grid-column: auto;

}

/* Mision */

.showcaseContainerMision{
    display: flex;
    justify-content: center;
    padding-top: 7%;
}
.showcaseTrheeMision{
    width: 100%;
    height: 300px;
    background-color: rgba(0,75,173,.7);    
    color: 	#000;
    margin-top: 3%;
    font-family: 'Raleway', sans-serif;
    border-bottom-right-radius: 150px;
    border-top-left-radius: 150px;
}
.textTwoMision{
    display: grid;
    grid-template-columns: repeat(2 ,1fr);
    justify-content: center;
}
.textTwoMision>p{
    margin-top: 5%;
    padding: 1%;
    grid-column: 2;
    font-size: 2rem;
    font-family: 'Raleway', sans-serif;
    color: #FFFF;
}
.LigoSuplytwoMision{
    margin-top: 5%;
    margin-left: 40%;
    grid-column: 1;
}

.showcaseContainerVision{
    display: flex;
    justify-content: center;
    padding-top: 7%;
}
.showcaseTrheeVision{
    width: 100%;
    height: 300px;
    background-color: rgba(0,128,55,.7);    
    color: 	#000;
    font-family: 'Raleway', sans-serif;
    border-bottom-right-radius: 150px;
    border-top-left-radius: 150px;
}
.textTwoVision{
    display: grid;
    grid-template-columns: repeat(2 ,1fr);
    justify-content: center;
}
.textTwoVision>p{
    margin-top: 5%;
    /* padding: -1.5%; */
    grid-column: 2;
    font-size: 2rem;
    font-family: 'Raleway', sans-serif;
    color: #FFFF;
}
.LigoSuplytwoVision{
    margin-top: 5%;
    margin-left: 40%;
    grid-column: 1;
}
.cardTitle{
    color: rgba(0,128,55,.7);
    text-align: center;
}
.SecondTitleText{
    color: blue;
    text-align: center;
    font-size: 3rem;
    font-family: 'Raleway', sans-serif;

}
.titleSize{
    margin: 10% 0% -2% 0%;
    width: 100%;
    height: 50px;
}

/* contact form style */

.contactForm{
    width: 50%;
    margin-left: 25%;
    font-family: 'Raleway', sans-serif;
}
.cardLoginContent{
    align-items: center;
    width: 90%;
    display: grid;
    flex-direction: column;
    gap:5%;
    margin-bottom: 10%;
    margin-left: 27px;
}
.fieldsInput{
    margin-top: 10px;
}
.cadAccContent{
    display: flex;
    justify-content: center;
    margin-bottom: 5%;
}
/* loadingModal styles */

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #dfc;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }


@media(max-width:700px){

    .text{
        display: grid;
        grid-template-columns: repeat(1 ,1fr);
        justify-content: center;
    }
    .text>p{
        margin-top: 5%;
        padding: 5%;
        padding-left: 10%;
        font-size: 2.2rem;
        font-family: 'Raleway', sans-serif;
        color: #FFFF;
    }
    .LigoSuply{
        display: none;
    }

        /* product Page Hero slider */
    .heroContainer{
        padding-top: 35%;
        margin-bottom: 5%;
    }
    .image{
        width: 70%;
        margin-left: 15%;
    }
    /* mision */
    .textTwoMision{
        grid-template-columns: repeat(1 ,1fr);
    }
    .textTwoMision>p{
        margin-top: 5%;
        padding: 5%;
        padding-left: 6%;
        font-size: 1.5rem;
        font-family: 'Raleway', sans-serif;
        color: #FFFF;
    }
    .LigoSuplytwoMision{
        display: none;
    }

    .textTwoVision{
        grid-template-columns: repeat(1 ,1fr);
    }
    .textTwoVision>p{
        margin-top: 5%;
        padding: 5%; 
        padding-left: 6%;
        padding-top: 10%;
        grid-column: 2;
        font-size: 1.2rem;
        font-family: 'Raleway', sans-serif;
        color: #FFFF;
    }
    .LigoSuplytwoVision{
        display: none;
    }

    .SecondTitleText{
        color: rgba(0,128,55,1);
    
    }

    /* products */

    .showcaseContainer{
        padding-top: 25%;
    }
    .textTwo{
        grid-template-columns: repeat(1 ,1fr);
    }
    .textTwo>p{
        margin-top: 5%;
        padding: 5%;
        font-size: 1.7rem;
        padding-left: 10%;
    }
    .LigoSuplytwo{
        display: none;
    }

    /* payload */

    .payload{
        grid-template-columns: repeat(1,1fr);
        margin-bottom: 500%;
        width: 80%;
    }
    .cardContainer{
        grid-column: auto;
    }

    .client1{
        height: 650px;
        margin-bottom: 10%;
    }

    /* contact form styles  */

    .contactForm{
        width: 50%;
        margin-left: 25%;
        font-family: 'Raleway', sans-serif;
    }
    .cardLoginContent{
        align-items: center;
        width: auto;
        display: grid;
        flex-direction: column;
        gap:5%;
        margin-bottom: 30%;
        margin-left: 27px;
    }
    .fieldsInput{
        margin-top: 10px;
    }
    .cadAccContent{
        display: flex;
        justify-content: center;
        margin-bottom: 5%;
    }
    
}
